import { getBreadcrumbUrls } from "@lib/utils"
import clsx from "clsx"
import { Link } from "gatsby"
import { isEmpty } from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import "./Breadcrumbs.scss"
// import useDeviceMedia from "@hooks/useDeviceMedia"

const Breadcrumbs = ({ staticURLs, menuData, whiteColor, backButton, classn}) => {
  // const { isMobile } = useDeviceMedia()

  const breadcrumbs =
    staticURLs || (!isEmpty(menuData) && getBreadcrumbUrls(menuData))

  if (!breadcrumbs || menuData?.slug === "home") return null

  const handleBack = () => {
    window?.history?.back()
  }

  return (
    <div className={clsx("breadcrumbs-wrap", whiteColor && "white-color", classn)}>
      <Container className="breadcrumbs-container">
        <nav className="breadcrumbs">
          <ol className="breadcrumb">
            {backButton && (
              <li
                className="back breadcrumb-item"
                onClick={() => handleBack()}
                onKeyPress={() => handleBack()}
              >
                <span>Back</span>
                <div className="vertical-divider" />
              </li>
            )}
            <li className="enable-link-home breadcrumb-item">
              <Link className={clsx("breadcrumb-link", "enable-link")} to="/">
                Home
              </Link>
            </li>
            {breadcrumbs.map((breadcrumb, index) => (breadcrumb?.label &&
              <li
                key={`${breadcrumb?.url}breadcrumb`}
                className={clsx(
                  " breadcrumb-item",
                  index === breadcrumbs.length - 1 && "active"
                )}
              >
                <Link
                  className={clsx(
                    "breadcrumb-link",
                    index === breadcrumbs.length - 1
                      ? "disable-link"
                      : "enable-link"
                  )}
                  to={breadcrumb?.url}
                >
                  {breadcrumb?.label}
                </Link>
              </li>
            ))}
          </ol>
        </nav>
      </Container>
    </div>
  )
}

export default Breadcrumbs
